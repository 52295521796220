import styled from 'styled-components';
import { white } from 'constants/theme';

export const SocialCss = styled.nav`
  height: 80px;
  padding-bottom: 35px;
  margin-bottom: 18px;
  border-bottom: solid 1px #ffffff;
  a {
    display: inline-block;
    text-decoration: none;
    height: 44px;
    padding: 0px 10px;
    &:hover {
      color: inherit;
    }
    div {
      color: inherit;
      font-size: 36px !important;
      font-family: 'simple-line-icons';
      color: white;
    }
  }
  .social-logo {
    color: ${white};
    height: 44px;
    padding: 10px;
  }
  .facebook {
    background-color: #3c5b9b;
  }
  .twitter {
    background-color: #2daae1;
  }
  .youtube {
    background-color: #e52d27;
  }
  .wechat-container {
    display: inline-block;
    height: 44px;
  }
  .wechat {
    background-color: #00c80f;
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
  }
`;
