/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    placeholder: PropTypes.string,
    name: PropTypes.string,
    changeValue: PropTypes.func,
    reset: PropTypes.bool,
    userInput: PropTypes.string,
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.myInputRef = React.createRef();
    this.suggestionsRef = React.createRef();
  }

  setSearchTerm = term => {
    const { changeValue } = this.props;
    changeValue(term);
    this.myInputRef.current.value = term;
  }

  onClick = e => {
    this.setSearchTerm(e.currentTarget.innerText);
    this.suggestionsRef.current.style.display = 'none';
  };

  componentDidMount() {
    this.myInputRef.current.value = this.props.userInput;
    this.suggestionsRef.current.style.display = 'none';
    this.myInputRef.current.addEventListener('input', e => {
      e.stopPropagation();

      this.setSearchTerm(e.currentTarget.value);

      const { suggestions } = this.props;
      const userInput = e.currentTarget.value;

      const filteredSuggestions =
        suggestions &&
        suggestions.filter(
          suggestion =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

      if (userInput.length > 0) {
        const suggestionsList = filteredSuggestions.map(
          suggestion => `<li>${suggestion}</li>`
        );
        if (suggestionsList.length > 0) {
          this.suggestionsRef.current.style.display = 'block';
          this.suggestionsRef.current.innerHTML = suggestionsList.join('');
          const items = this.suggestionsRef.current.querySelectorAll('li');
          items.forEach(item => item.addEventListener('click', this.onClick));
        } else {
          this.suggestionsRef.current.style.display = 'none';
        }
      } else {
        this.suggestionsRef.current.style.display = 'none';
      }
    });
  }

  render() {
    const {
      onKeyDown,
      props: { placeholder, userInput },
    } = this;

    return (
      <Fragment>
        <input
          autoComplete="off"
          id={'input'}
          type="text"
          ref={this.myInputRef}
          onChange={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.changeValue(e.target.value);
          }}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          maxLength="100"
          className={
            userInput.length > 0
              ? 'general-form-input active keyword-autocomplete'
              : 'general-form-input keyword-autocomplete'
          }
          onBlur={() =>
            setTimeout(() => {
              if (this.suggestionsRef.current)
                this.suggestionsRef.current.style.display = 'none';
            }, 200)
          }
        />
        <ul className="suggestions" ref={this.suggestionsRef}></ul>
      </Fragment>
    );
  }
}

export default Autocomplete;
